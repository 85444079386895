import { axiosExtended } from 'api/axios'

import { GetApiKeysResponse } from './getApiKeys.types'
import { getGetApiKeysUrl } from '../urls'

export const getApiKeys = async (bubbleAppId: string | number) => {
  const { href } = getGetApiKeysUrl(bubbleAppId)
  const response = await axiosExtended.get<GetApiKeysResponse>(href, {
    withAuth: true,
  })
  return response.data
}
