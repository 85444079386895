import { useRouter } from 'next/router'
import { TokenStorage } from 'utils/auth'

import { useUserContext } from './useUserContext'

export const useLogout = () => {
  const router = useRouter()
  const { setUser } = useUserContext()

  const logout = () => {
    setUser(null)

    const tokenStorage = TokenStorage.getInstance()
    tokenStorage.removeAccessToken()
    tokenStorage.removeRefreshToken()
    void router.replace('/login')
  }

  return logout
}
