import { refreshTokens as refreshTokensApi } from 'api/requests/auth'
import { getCreateConversationUrl } from 'api/requests/conversation/urls'
import {
  getCheckIsEmailRegisteredUrl,
  getLoginUrl,
  getRefreshTokensUrl,
  getRegisterUrl,
  getSetupBubbleAppDatabaseUrl,
} from 'api/urls'
import axios, { AxiosErrorExtended, AxiosInstanceExtended } from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { USER } from 'constants/cookieKeys'
import { differenceInMilliseconds } from 'date-fns'
import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'
import { TokenStorage } from 'utils/auth'
import { deleteCookie } from 'utils/cookie'
import { isBrowser } from 'utils/environment'

const tokenStorage = TokenStorage.getInstance()

const HIDE_SUCCESS_TOAST_FOR_PATHNAMES = [
  getLoginUrl().pathname,
  getCheckIsEmailRegisteredUrl().pathname,
  getRefreshTokensUrl().pathname,
  getCreateConversationUrl().pathname,
]

const HIDE_ERROR_TOAST_FOR_PATHNAMES = [
  getRegisterUrl().pathname,
  getCheckIsEmailRegisteredUrl().pathname,
  getRefreshTokensUrl().pathname,
  getSetupBubbleAppDatabaseUrl().pathname,
  getCreateConversationUrl().pathname,
]

export const axiosExtended = axios.create() as AxiosInstanceExtended

const refreshAuthTokens = async () => {
  try {
    const refreshToken = tokenStorage.getRefreshToken()
    if (!refreshToken) {
      tokenStorage.removeAccessToken()
      tokenStorage.removeRefreshToken()

      if (isBrowser()) {
        deleteCookie(USER)
        window.location.replace('/account/login')
      }

      return await Promise.reject()
    }

    const refreshTokenDecoded = jwtDecode(refreshToken)
    const refreshTokenExpirationDate = (refreshTokenDecoded as any).exp * 1000
    const isRefreshTokenExpired =
      differenceInMilliseconds(refreshTokenExpirationDate, new Date()) < 1
    if (isRefreshTokenExpired) {
      tokenStorage.removeAccessToken()
      tokenStorage.removeRefreshToken()

      if (isBrowser()) {
        deleteCookie(USER)
        window.location.replace('/account/login')
      }

      return await Promise.reject()
    }

    const response = await refreshTokensApi({
      refreshToken,
    })
    if (response.data) {
      const { accessToken, refreshToken } = response.data
      tokenStorage.setAccessToken(accessToken)
      tokenStorage.setRefreshToken(refreshToken)
    }

    return await Promise.resolve()
  } catch (error) {
    console.error(error)
    return Promise.reject(error)
  }
}

createAuthRefreshInterceptor(axiosExtended, refreshAuthTokens)

axiosExtended.interceptors.request.use(config => {
  if (!config.withAuth) {
    return config
  }

  const accessToken = tokenStorage.getAccessToken()
  if (config.headers && accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`
  }
  return config
})

axiosExtended.interceptors.response.use(
  response => {
    const showToastForPathname = response.config.url
      ? !HIDE_SUCCESS_TOAST_FOR_PATHNAMES.includes(
          new URL(response.config.url).pathname
        )
      : false
    const successMessage = response.data.data?.message as string
    if (isBrowser() && showToastForPathname && successMessage) {
      toast.success(successMessage)
    }

    return response
  },
  async (error: AxiosErrorExtended) => {
    const showToastForPathname = error?.config?.url
      ? !HIDE_ERROR_TOAST_FOR_PATHNAMES.includes(
          new URL(error.config.url).pathname
        )
      : false
    if (isBrowser() && showToastForPathname) {
      const errorMessage =
        (error.isAxiosError ? error.response?.data.error : error.message) ??
        'Something went wrong'
      toast.error(errorMessage)
    }

    return Promise.reject(error)
  }
)
