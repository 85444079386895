import { API_URL } from 'constants/env'
import { GetBubbleDataTypesListData } from './getBubbleDataTypesList'
import { GetBubbleAppInfoData } from './getBubbleAppInfo'
import { GetBubbleDataTypesAndFieldsData } from './getBubbleDataTypesAndFields'

export const getCreateBubbleAppUrl = (): URL => {
  const url = `${API_URL}/bubbleApp/create`
  return new URL(url)
}

export const getSetupBubbleAppDatabaseUrl = (): URL => {
  const url = `${API_URL}/bubbleApp/setupDatabase`
  return new URL(url)
}

export const getVerifyConnectionUrl = (): URL => {
  const url = `${API_URL}/bubbleApp/verifyConnection`
  return new URL(url)
}

export const getGetBubbleDataTypesUrl = (
  params?: GetBubbleDataTypesListData
): URL => {
  const url = `${API_URL}/bubbleApp/bubbleDataTypes/list`
  if (!params) {
    return new URL(url)
  }

  const { bubbleAppId } = params
  return new URL(url + String(bubbleAppId))
}
export const getGetBubbleDataTypesAndFieldsUrl = (
  params?: GetBubbleDataTypesAndFieldsData
): URL => {
  const url = `${API_URL}/bubbleApp/${params?.bubbleAppId}/typesAndFields`
  return new URL(url)
}

export const getGetBubbleAppInfoUrl = (params?: GetBubbleAppInfoData): URL => {
  const url = `${API_URL}/bubbleApp/${params?.bubbleAppId}/info`
  return new URL(url)
}

export const getGetBubbleAppConnectionInfoUrl = (
  params?: GetBubbleAppInfoData
): URL => {
  const url = `${API_URL}/bubbleApp/${params?.bubbleAppId}/connectionInfo`
  return new URL(url)
}

export const getGetBubbleAppDecryptedPasswordUrl = (
  bubbleAppId: string | number
): URL => {
  const url = `${API_URL}/apps/${bubbleAppId}/decryptPassword`
  return new URL(url)
}

export const getGetMyBubbleAppsUrl = (): URL => {
  const url = `${API_URL}/bubbleApp/myApps`
  return new URL(url)
}

export const getGetSchemaErrorDetailsUrl = (
  bubbleAppId: string | number
): URL => {
  const url = `${API_URL}/bubbleApp/${bubbleAppId}/schemaErrorDetails`
  return new URL(url)
}

export const getGetBubbleAppSubscriptionInfoUrl = (
  bubbleAppId: string | number
): URL => {
  const url = `${API_URL}/bubbleApp/${bubbleAppId}/subscriptionInfo`
  return new URL(url)
}

export const getUpdateSyncScheduleUrl = (): URL => {
  const url = `${API_URL}/bubbleApp/syncSchedule`
  return new URL(url)
}
