export enum UserStatuses {
  Verified = 'verified',
  Rejected = 'rejected',
  Pending = 'pending',
  Deleted = 'deleted',
  Invited = 'invited',
}

export enum UserRegistrationStatuses {
  Pending = 'pending',
  Rejected = 'rejected',
  Approved = 'approved',
  Invited = 'invited',
}

export enum UserDeletionStatuses {
  Pending = 'pending',
  Deleted = 'deleted',
}
