import browserCookies from 'browser-cookies'
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'constants/cookieKeys'
import { getCookie, setCookie } from 'utils/cookie'
import { isBrowser } from 'utils/environment'

// TokenStorage is a singleton(https://patterns.dev/posts/singleton-pattern/)
// Needed to pass tokens between the server side and the client side.
export class TokenStorage {
  private static instance: TokenStorage

  private accessToken = ''

  private refreshToken = ''

  public static getInstance(): TokenStorage {
    if (!TokenStorage.instance) {
      TokenStorage.instance = new TokenStorage()

      if (isBrowser()) {
        TokenStorage.instance.setAccessToken(getCookie(ACCESS_TOKEN))
        TokenStorage.instance.setRefreshToken(getCookie(REFRESH_TOKEN))
      }
    }

    return TokenStorage.instance
  }

  public getAccessToken() {
    return this.accessToken ?? ''
  }

  public setAccessToken(token: string) {
    this.accessToken = token

    if (token && isBrowser()) {
      setCookie(ACCESS_TOKEN, token)
    }
  }

  public removeAccessToken() {
    this.accessToken = ''
    browserCookies.erase(ACCESS_TOKEN)
  }

  public getRefreshToken() {
    return this.refreshToken ?? ''
  }

  public setRefreshToken(token: string) {
    this.refreshToken = token

    if (token && isBrowser()) {
      setCookie(REFRESH_TOKEN, token)
    }
  }

  public removeRefreshToken() {
    this.refreshToken = ''
    browserCookies.erase(REFRESH_TOKEN)
  }
}
