import { API_URL } from 'constants/env'
import { GetTypesListData } from './getTypesList'
import { GetTypeRecordsData } from './getTypeRecords'
import { getSearchParamsWithSeparatorFromObject } from 'utils/url'
import { GetTypeFieldsListData } from './getTypeFieldsList'
import { GetRecordData } from './getRecord'

export const getTypesListUrl = (query: GetTypesListData): URL => {
  const url = `${API_URL}/apps/${query.bubbleAppId}/types/list`
  if (!query.search) {
    return new URL(url)
  }
  const searchParams = getSearchParamsWithSeparatorFromObject({
    search: query.search,
  })
  return new URL(url + searchParams)
}

export const getTypeFieldsListUrl = (query: GetTypeFieldsListData): URL => {
  const url = `${API_URL}/apps/${query.bubbleAppId}/typeFields/list`
  if (!query.search) {
    return new URL(url)
  }
  const searchParams = getSearchParamsWithSeparatorFromObject({
    search: query.search,
  })
  return new URL(url + searchParams)
}

export const getTypeRecordsUrl = (params: GetTypeRecordsData): URL => {
  const url = `${API_URL}/apps/${params.bubbleAppId}/types/${encodeURIComponent(
    params?.bubbleDataTypeName
  )}/records/list`

  return new URL(url)
}

export const getGetTypesRecordCountUrl = (
  bubbleAppId: number | string
): URL => {
  const url = `${API_URL}/apps/${bubbleAppId}/types/counts`

  return new URL(url)
}

export const getRecordUrl = (params: GetRecordData): URL => {
  const url = `${API_URL}/apps/${params.bubbleAppId}/types/${encodeURIComponent(
    params?.bubbleDataTypeName
  )}/records/${params.bubbleRecordId}`

  return new URL(url)
}

export const getGetConnectionDetailsUrl = (
  bubbleAppId: string | number
): URL => {
  const url = `${API_URL}/apps/${bubbleAppId}/connectionDetails`
  return new URL(url)
}

export const getGetSqlQueryResultsUrl = (bubbleAppId: string | number): URL => {
  const url = `${API_URL}/apps/${bubbleAppId}/sqlQuery`
  return new URL(url)
}

export const getGetTypesStatsUrl = (bubbleAppId: string | number): URL => {
  const url = `${API_URL}/apps/${bubbleAppId}/types/stats`
  return new URL(url)
}

export const getUsersChartDataUrl = (bubbleAppId: string | number): URL => {
  const url = `${API_URL}/apps/${bubbleAppId}/chartData/users`
  return new URL(url)
}

export const getHeatMapDataUrl = (bubbleAppId: string | number): URL => {
  const url = `${API_URL}/apps/${bubbleAppId}/chartData/heatmap`
  return new URL(url)
}
