export enum BubbleDataTypeFieldTypes {
  text = 'text',
  file = 'file',
  image = 'image',
  user = 'user',
  custom = 'custom',
  option = 'option',
  number = 'number',
  date = 'date',
  numberRange = 'number_range',
  dateRange = 'date_range',
  dateInterval = 'dateinterval',
  boolean = 'boolean',
  geographicAddress = 'geographic_address',
}

export interface BubbleDataTypeRecord {
  'Unique ID': string
  'Created By': string | null
  'Created Date': string
  'Modified Date': string
  [key: string]:
    | string
    | string[]
    | number
    | number[]
    | boolean
    | boolean[]
    | string[][]
    | number[][]
    | []
    | null
    | null[]
}
